<template>
  <div>
    <div class="row">
      <div class="col-xxl-2 col-12 col-md-3 pt-0 pb-0">
        <div class="card gutter-b">
          <b-form-select
            v-model="selectCustomer"
            :options="filterCustomer"
          ></b-form-select>
        </div>
      </div>
      <div class="col-xxl-2 col-12 col-md-3 pt-0 pb-0">
        <div class="card gutter-b">
          <b-form-select
            v-model="selected.customerBranch"
            :options="filterCustomerBranch"
          ></b-form-select>
        </div>
      </div>
    </div>
    <!--begin::Import Employee-->
    <div class="row gutter-b">
      <div class="col-xxl-12">
        <b-card
          title="Upload File Excel"
          header-tag="header"
          footer-tag="footer"
        >
          <template v-slot:header>
            <h6 class="mb-0">Form Service</h6>
          </template>
          <b-form-group label-for="file-default">
            <b-form-file
              placeholder="Choose a file or drop it here..."
              id="file-default"
              v-model="selectedFile"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ></b-form-file>
          </b-form-group>
          <template v-slot:footer>
            <b-button variant="primary mr-3" @click="handleSubmit"
              >Submit</b-button
            >
            <a
              href="https://storage.googleapis.com/gardtrax-dev/import-employee-sample.xlsx"
              class="btn btn-success"
              >Download Template</a
            >
          </template>
        </b-card>
      </div>
    </div>
    <!--end::Import Employee-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import mixin from "@/mixins/index";
import { mapGetters } from "vuex";

export default {
  name: "dashboard",
  mixins: [mixin],
  data() {
    return {
      selectedFile: null,
      selected: {
        customer: null,
        customerBranch: null,
      },
      filterCustomer: [{ value: null, text: "Filter Site Group" }],
      filterCustomerBranch: [{ value: null, text: "Filter Site" }],
    };
  },
  components: {},
  async mounted() {
    this.filterCustomer = await this.getCustomer();
    this.selected.customer = this.filterCustomer[1].value;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Import Employee" },
    ]);
    this.getCustomer();
  },
  computed: {
    ...mapGetters(["permission"]),
    selectCustomer: {
      get() {
        return this.selected.customer;
      },
      set(value) {
        this.selected.customer = value;
        if (value === null) {
          this.selected.customerBranch = null;
          this.filterCustomerBranch = [{ value: null, text: "Pilih Site" }];
        } else this.getCustomerBranchList(value);
      },
    },
  },
  methods: {
    async handleSubmit() {
      let formData = new FormData();
      formData.append("file", this.selectedFile);
      try {
        await this.$axios.post(
          `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/employee/import`,
          formData,
          {
            customer_branch_id: this.selected.customerBranch,
          }
        );
        this.$bvToast.toast("Success", {
          title: "success",
          variant: "success",
          solid: true,
        });
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message ?? "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    async getCustomerBranchList(customer_id) {
      this.isLoading = true;
      if (
        !this.permission.some((item) => item.name === "can_create_customer")
      ) {
        const customerBranch = this.$store.getters.currentUser.customer_branch;
        this.filterCustomerBranch = [{ value: null, text: "Select All" }];
        customerBranch.map((item) => {
          this.filterCustomerBranch.push({
            value: item.id,
            text: item.name,
          });
        });
        this.selected.customerBranch = this.filterCustomerBranch[1].value;
      } else {
        await this.$axios
          .get(
            `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/customer/${customer_id}/customer-branches`
          )
          .then(({ data }) => {
            this.filterCustomerBranch = [{ value: null, text: "Select All" }];
            data.list.map((item) => {
              this.filterCustomerBranch.push({
                value: item.id,
                text: item.name,
              });
            });
          });
        this.selected.customerBranch = this.filterCustomerBranch[0].value;
      }
      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss"></style>
